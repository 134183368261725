
.setting-component {
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }


  &.FILE {
    & .setting-component__value {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-right: 0;
      &.logo {
        align-items: flex-start;
      }
      & .select-file-component {
        margin-top: 4px;
        width: 64px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
      }
    }
  }

  &.CODE {
    flex-wrap: wrap;

    & .setting-component__header {  
      width: 100%;
      max-width: 100%;
    }

    & .setting-component__action {
      width: 100%;

    }

    & pre {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }

    & code {
      display: block;
      border: solid 0.052vw #dde0e6;
      padding: 0px 0 24px 0px !important;
      width: 100%;

      @media (max-width: 1365px) {
        border: solid 0.073vw #dde0e6;
      }
    }

    & .button {
      margin-left: auto;
    }
  }

  &.INPUT {
    flex-wrap: wrap;

    &:last-child {
      & input { 
        margin-bottom: 0;
      }

      & .setting-component__action--group {
        margin-bottom: 0;
      }
    }

    & .setting-component__header { 
      width: 100%;
 
      & p {
        margin-bottom: 0.417vw;
        @media (max-width: 1365px) {
          margin-bottom: 0.586vw;  
        }

        @media (max-width: 1024px) {
          margin-bottom: 8px;
        }

        @media (max-width: 767px) {
          margin-bottom: 8px;
        }
      }
    }

    & .setting-component__action {
      width: 100%;

      &--group {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1.25vw;
        @media (max-width: 1365px) {
          margin-bottom: 1.758vw;    
        }

        @media (max-width: 1024px) {
          margin-bottom: 24px;
          flex-direction: column;
        }

        @media (max-width: 767px) {
          margin-bottom: 24px;
        }

        & button {
          background-color: #11deb3;
          color: white;
          text-decoration: none;
          width: 10.417vw;
          height: 2.483vw;
          border-radius: 0.208vw;
          margin-left: 0.833vw;
          @media (max-width: 1365px) {
            width: 14.652vw;
            height: 3.39vw;
            border-radius: 0.293vw;
            margin-left: 1.172vw;
          }
  
          @media (max-width: 1024px) {
            width: 100%;
            height: 40px;
            border-radius: 4px;
            margin-left: 0;
            margin-top: 8px;
            font-size: 18px;
          }

          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
    }

    & input {
      background-color: white;
      width: 100%;
      height: 2.5vw;
      border-radius: 0.208vw;
      border: solid 0.052vw #dde0e6;
      padding: 0 0.417vw;
      @media (max-width: 1365px) {
        height: 3.516vw;
        border-radius: 0.293vw;
        border: solid 0.073vw #dde0e6;
        padding: 0 0.586vw;
      }

      @media (max-width: 1024px) {
        height: 48px;
        border-radius: 4px;
        border: solid 1px #dde0e6;
        padding: 0 8px;
      }

      @media (max-width: 767px) {
        height: 48px;
        border-radius: 4px;
        border: solid 1px #dde0e6;
        padding: 0 8px;
      }
    }
  }

  &.EDIT {
    @media (max-width: 1024px){
      margin-bottom: 16px;
    }
    
    & p {
      @media (max-width: 1024px){
         margin-bottom: 8px;
      }
    }

    & button {
      &:disabled {
        cursor: initial;
        opacity: 0.5;
      }
    }

    & .setting-component__action--group {
      @media (max-width: 1365px){
        display: flex;
        flex: 1;
      }
  
      @media (max-width: 1024px){
           margin-bottom: 8px;
      }
  
      & input {
        height: 2.5vw;
        margin-right: 1.25vw;
        border-radius: 0.208vw;
        border: solid 0.052vw #dde0e6;
        background-color: white;
        padding-left: 0.833vw;
        flex: 1;

        @media (max-width: 1365px){
          height: 3.516vw;
          margin-right: 1.758vw;
          border-radius: 0.293vw;
          border: solid 0.073vw #dde0e6;
          padding-left: 1.172vw;
        }
    
        @media (max-width: 1024px){
          height: 48px;
          margin-right: 24px;
          border-radius: 4px;
          border: solid 1px #dde0e6;
          background-color: white;
          padding-left: 16px;
        }
        
        @media (max-width: 767px){ 
          margin-right: 8px;
        }

        &:active {
          outline: 0;
        }
      }
    }
  }

  & h3 {
    margin-top: 0;
    font-size: 0.729vw;

    @media (max-width: 1365px) {
      font-size: 1.025vw;
    }

    @media (max-width: 1024px) {
      font-size: 1.367vw;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  & p {
    font-size: 0.729vw;
    margin: 0.417vw 0 1.25vw 0;
    @media (max-width: 1365px) {
      margin-bottom: 1.172vw;
      font-size: 1.025vw;
    }

    @media (max-width: 1024px) {
      margin-bottom: 16px;
      font-size: 1.367vw;
    }

    @media (max-width: 767px) {
      margin-bottom: 16px;
      font-size: 14px;
    }
  }

  &__header {
    @media (max-width: 1365px) {
      width: 100%;
    }

    @media (max-width: 1024px){
      margin: 1.563vw 0 1.563vw 0;
    }

    @media (max-width: 767px){
       margin: 12px 0 12px 0;
    }

    & p {
      line-height: 24px;

      &:first-child {
        margin-bottom: 0;
      }
    }

    & h3 {
      @media (max-width: 1024px){
        margin-bottom: 0;
      }
    }
  }

  &__value {
    margin-left: auto;
    margin-right: 1.615vw;
    font-size: 0.729vw;
    font-weight: normal;
    display: flex;
    align-items: baseline;
    white-space: nowrap;
    @media (max-width: 1365px) {
      margin-left: unset;
      margin-right: 2.271vw;
      font-size: 1vw;
    }

    @media (max-width: 1024px){
      font-size: 1.367vw;
    }

    @media (max-width: 767px) {
      margin-right: 31px;
      font-size: 14px;
    }

    &.not-clickable {
      margin-right: 8px;
    }

    & img, & .image-placeholder {
      width: 3.333vw;
      height: 3.333vw;
      border-radius: 0.208vw;
      border: solid 0.052vw #dde0e6;
      padding: 0.208vw;

      @media (max-width: 1365px) {
        width: 4.689vw;
        height: 4.689vw;
        border-radius: 0.293vw;
        border: solid 0.073vw #dde0e6;
        padding: 0.293vw;
      }

      @media (max-width: 767px) {
        width: 64px;
        height: 64px;
        border-radius: 4px;
        border: solid 1px #dde0e6;
        padding: 4px;
      }
    }
  }

  &__action {
    display: flex;
    align-items: baseline;
    
    @media (max-width: 1365px) {
      margin-bottom: 1.758vw;
      flex: 1;
      justify-content: flex-end;
    }

    @media (max-width: 1024px) {
      margin-bottom: 0;
    }

    & button, & label { 
      font-size: 0.729vw;
      font-weight: bold;
      color: #11deb3;
      text-decoration: underline;
      outline: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;

      @media (max-width: 1365px) {
        font-size: 1.026vw;
      }
  
      @media (max-width: 1024px) {
        font-size: 1.367vw;
      }

      @media (max-width: 767px) {
        font-size: 14px;
      }

      &:active {
        transform: scale(1.05);
      }
    }

    &--column-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (max-width: 767px){
        margin-top: 8px;
      }

      & > div {
        padding: 0 !important;
      }
    }
  }
}