.InnerContainer{
    height: 80px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #474646;
    font-family: roboto, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    @media (max-width: 767px) {
        min-height: 47px;
        height: 47px;
    }

    > img{
        margin-left: 10px;
        width: 36px;
    }
}

.FooterContainer {
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    max-height: 496px;

    @media (max-width: 767px) {
        max-height: auto;
    }

    & .register-now-link {
        width: 232px;
        max-width: 100%;
        height: 40px;
        border-radius: 32px;
        background-color: #ff7676;
        font-size: 12px;
        font-weight: normal;
        line-height: 22px;
        text-align: center;
        margin-top: 32px;
        min-width: auto;

        @media (max-width: 767px) {
            margin-top: 8px;
        }
    }

    & .ada-footer-background {
        position: absolute;
        left: 0;
        background: url('../../images/cardano-bg-element.png') no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        z-index: 1;
        pointer-events: none;
        left: 0;

        @media (max-width: 767px) {
            background: url('../../images/cardano-bg-element-mobile.png') no-repeat;
            width: 100%;
            background-size: cover;
            height: 100%;
            z-index: 999;
            position: absolute;
            top: -7px;
            left: 0;
            max-width: 736px;
        }
    }


    & .accept {
        width: 100%;
        height: 424px;
        background-color: #335cbe;
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0 18.75vw;
        align-items: center;
        max-width: 100%;
        flex-wrap: wrap;
        box-sizing: border-box;
        @media (max-width: 767px) {
            justify-content: flex-start;
            padding: 64px 32px;
            min-height: 344px;
        }
        
        & .ada {
            padding-right: 64px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 100%;
            justify-content: space-between;
            @media (max-width: 1365px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 100%;
                padding-right: 0;
                z-index: 9999;
            }
            &.register-section {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            
            & h2 {
                font-size: 48px;
                font-weight: normal;
                line-height: 32px;
                color: white;

                @media (max-width: 767px) {
                    font-size: 24px;
                    // margin-top: 128px;
                    margin-bottom: 0;
                }
            }
            & h3 {
                font-size: 20px;
                line-height: 34px;
                color: white;
                margin-top: 24px;
                font-weight: normal;
                margin-bottom: 0vmin;
                max-width: 500px;
                @media (max-width: 767px) {
                    width: 280px;
                    height: 33px;
                    font-size: 13px;
                    line-height: 19px;
                }
            }
        }
    }
}


.merchant-component {
    
    width: 256px;
    height: 152px;
    padding: 24px 24px 24px 22.7px;
    border-radius: 8px;
    background-color: white;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9999;

    @media (max-width: 767px) {
        width: 296px;
        max-width: 100%;
        height: 152px;
        max-height: 152px;
        margin: 64px 0;
        padding: 0;
        margin: 0 auto;
        margin-top: 54px;

    }

    & .register-now-link {
        background-color: #0033ad;
    }
}