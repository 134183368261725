@font-face {
    font-family: 'Chivo';
    src: local('Chivo'), url(./fonts/Chivo/Chivo-Regular.ttf) format('truetype');
}
  
html {
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
}