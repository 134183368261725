.confirmed, .payment-sent {
    & .payment-history-component{
        & .payment-history-component--transactions {
            margin-bottom: 0;
            padding-bottom: 0;
            border: 0;
        }
    }
}

.payment-history-component {
    max-width: 100%;
    margin: 0 auto;
    margin-top: 24px;
    border-top: solid 1px #eef3ff;
    padding-top: 24px;
    
    & h2 {
      @media (max-width: 767px) {
        font-size: 14px;
      }
      
    }
    &--title {
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        color: black;
        margin-bottom: 24px;

        @media (max-width: 767px) {
            margin-top: 0;
        }
    }

    &--row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        &-title {
            font-size: 12px;
            font-weight: normal;
            line-height: 16px;
            color: #474646;
        }

        &-amount {
            font-size: 14px;
            line-height: 16px;
            color: #474646;
        }
    }
}