.welcome-component {
    max-width: 50vw;
    display: flex;
    flex-direction: column;

    & .adapay {
        width: 359px;
        height: 188px;
        padding: 0;
        margin-top: 0;

        @media (max-width: 767px) {
            width: 136px;
            height: 71.2px;
            margin: 0;
            margin-bottom: 40px;
        }
    }
}