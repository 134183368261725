.button-settings-component {
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1024px) {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.payment-button-component {
  display: flex;
  align-items: center;
  justify-content: baseline;
  flex-direction: column;
  
  &.is-active {
    width: 100%;
    flex-wrap: wrap;

    &.small, &.medium, &.large {
      & img {
        display: block;
      }
    }
  }

  & img {
    margin-top: 1.042vw;
    @media (max-width: 1365px) {
      margin-top: 1.465vw;      
    }

    @media (max-width: 1024px) {
      margin-top: 20px;
    }

    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }

  &.small {
    & img {
      width: 120px;
      height: 24px;
  
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &.medium {
    & img {
      width: 159.994px;
      height: 32.006px;
  
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &.large {
    & img {
      width: 240px;
      height: 48px;

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;

    & input {
      margin-right: 8px;
    }

    &--type {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & span {
        font-size: 0.729vw;
        
        @media (max-width: 1365px) {
          font-size: 1.026vw;
        }

        @media (max-width: 1024px) {
          font-size: 1.825vw;
        }
              
        @media (max-width: 767px) {
          font-size: 12px;
        }

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  &__divider {
    max-height: 100%;
    width: 0.052vw;
    height: 5.729vw;
    border-left: 0.052vw solid #dde0e6;

    @media (max-width: 1365px) {
      width: 0.073vw;
      height: 8.059vw;
      border-left: 0.073vw solid #dde0e6;     
    }

    @media (max-width: 1024px) {
      display: none;
    }

    @media (max-width: 767px) {
      width: 1px;
      height: 110px;
      border-left: 1px solid #dde0e6;
    }
  }
}