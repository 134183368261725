.transaction-row-component {
    margin-top: 24px;
    * {
        font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    &.confirmed {
        & .transaction-row-component__date {
            & span {
                color: #11deb3;
            }
        }
    }

    &.pending {
        & .transaction-row-component__date {
            & span {
                color: #1c1cff;
            }
        }
    }

    &__header {
        font-size: 14px;
        line-height: 1;
        display: flex;
        align-items: center;
        
        & img {
            margin-right: 8px;
            margin-bottom: 2px;
            max-width: 24px;
            max-height: 24px;
        }
        
        & span {
            color: #474646;
        }
        
        & b {
            margin-left: auto;
        }
    }

    &__date {
        margin-top: 8px;
        display: flex;
        align-items: center;

        & span {
            font-size: 12px;
            font-weight: normal;
            line-height: 16px;
            color: #1c1cff;
            text-transform: capitalize;
        }

        & b {
            font-size: 12px;
            line-height: 16px;
            color: #474646;
            margin-left: auto;
        }
    }

    &__address {
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
        color: #5190e4;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        display: block;
    }

}