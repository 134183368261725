.modal {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100vh;
    animation: fadeIn 0.3s;
    background-color: rgba(#11254d, 0.5);

    @keyframes fadeIn {
        from {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }

    & .close-button {
        cursor: pointer;
        transition: 0.3s all;
        background-color: transparent;
        border: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;

        &:active {
            transform: scale(1.1);
        }

        &:focus {
            outline: none;
        }
    }

    &.basic {
        padding: 0 14px;

        & .modal__container {
            padding: 80px;
            max-height: 280px;
            position: relative;
            height: 100%;
            max-width: 608px;
            border-radius: 24px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            background-color: white;
           

            @media (max-width: 767px) {
                width: 100%;
                height: 100vh;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    &__container {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        & .evaluate-modal{
            width: 100%;

            & .button{
                height: 56px;
            }
        }
    }
}

.evaluate-modal {
    & h2 {
       font-size: 18px;
       font-weight: normal;
       line-height: 1.5;
       text-align: left;
       color: white;
    }
 
    &__input-group {
       margin-top: 24px;
    }
 
    & form {
       & .button {
          background-color: #2bbede;
       }
    }
 
    & .modal-info {
       height: 56px;
       border-radius: 8px;
       box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);
       width: 100%;
       background-color: white;
       display: flex;
       align-items: center;
 
       & .modal-info-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
 
          & .text {
             margin-left: 15px;
             font-size: 18px;
             font-weight: normal;
             line-height: 0.78;
          }
 
          & .block {
             width: 80px;
             height: 56px;
             background-color: #ecf6f8;
             display: flex;
             justify-content: center;
             align-items: center;
             border-radius: 0 8px 8px 0;
             & img {
                width: 19px;
                height: 19px;
             }
 
             & span {
                font-size: 14px;
                font-weight: normal;
                line-height: 1;
                color: #288ec3;
                margin-left: 5px;
             }
          }
       }
    }
 }

 .thankyou-modal {
    text-align: center;
    
    & h2 {
        font-family: Chivo;
        font-size: 24px;
        font-weight: normal;
        line-height: 1;
        color: #0033ad;

        @media (max-width: 767px) {
            font-size: 18px;
        }
    }

    & p {
        margin: 32px 0 12px 0;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: center;
        color: #000f2e;
        @media (max-width: 767px) {
            font-size: 14px;
            margin: 8px 0;

        }
    }

    & a {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: center;
        color: #1c1cff;
        @media (max-width: 767px) {
            font-size: 14px;
        }
    }
 }