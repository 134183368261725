
.home-component {
    width: 100%;
    max-height: 940px;
    padding: 0;
    background-color: #0033ad;
    background-image: linear-gradient(to bottom, #0033ad, #335cbe);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    @media (max-width: 767px) {
        height: 100%;
        max-height: 100%;
    }

    & .ada-pay-container {
        position: absolute;
        max-width: 100%;
        height: 100%;
        left: 0;
        width: 57%;

        @media (max-width: 767px) {
            bottom: 0;
            display: flex;
        }

        & .ada-pay-background {
            background: url(../../../images/logoBG.svg) no-repeat;
            background-size: contain;
            width: 100%;
            max-width: 100%;
            height: 100%;
            position: relative;

            @media (max-width: 767px) {
                background: url(../../../images/logoBG-mobile.svg) no-repeat center center fixed;
                background-size: cover;
            }
        }

        @media (max-width: 1570px) {
            width: 100%;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100vh;
        max-width: 100%;
        z-index: 100;
        padding: 0 17vw;
        box-sizing: border-box;
        margin-top: 10.4vw;

        @media (max-width: 1600px) {
            padding: 0 12vw;
        }

        @media (max-width: 1365px) {
            padding: 0 5vw;
        }

        @media (max-width: 767px) {
            flex-direction: column;
            margin-top: 40px;
            justify-content: flex-start;
            height: auto;
            padding-bottom: 48px;
        }
        
        & .image {
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 512px;
            max-height: 512px;

            @media (max-width: 1200px) {
                max-width: 330px;
                max-height: 330px;
            }

            @media (max-width: 767px) {
                margin-top: 48px;
                max-width: 272px;
                max-height: 220px;
                padding-left: 48px;
            }
   
            & .circle-container {
                background-color: #2f66f3;
                width: inherit;
                height: inherit;
                max-width: inherit;
                max-height: inherit;
                border-radius: 100%;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;

                & img {
                    position: relative;
                    z-index: 2;
                    display: block;
                    max-width: inherit;
                    max-height: inherit;
                }
            }
        }

        & h1 {
            font-size: 64px;
            font-weight: bold;
            text-align: left;
            color: white;
            font-family: 'Chivo', Courier, monospace;
            margin: 0;
            max-width: 400px;

            @media (max-width: 767px) {
                width: 280px;
                height: 96px;
                font-size: 41px;
                font-weight: bold;
                font-stretch: normal;
                line-height: 44px;
            }
        }

        & h3 {
            font-size: 20px;
            line-height: 34px;
            color: white;
            margin-top: 24px;
            font-weight: normal;
            margin-bottom: 0vmin;
            max-width: 500px;
            
            @media (max-width: 767px) {
                width: 280px;
                height: 33px;
                font-size: 13px;
                font-weight: normal;
                line-height: 19px;
            }
        }
    }

    & footer {
        display: flex;
        width: 100%;
        align-items: center;
        height: 80px;

        @media (max-width: 767px) {
            min-height: 48px;
            height: 48px;
        }

        & div {
            padding: 0;
        }
    }
}