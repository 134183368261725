.expand-component {
   width: 100%;
   background: white;
   & .expand-component__header {
      cursor: pointer;
      z-index: 10;
      & .row-item-component { 
         &:first-child {
            display: flex;
            flex-wrap: wrap;
         }
      }
   }

   &.not-clickable {
      & .expand-component__header {
         cursor: unset;
      }
   }

   &.expanded {
      .expand-component__header {
         & .row-item-component {

            &.action {
               display: block;
            }
         }

         &--button > img {
            transform: rotateZ(180deg);
         }
      }
   }

   &__expanded {
      padding: 1.25vw 0 0 0.8333333333333334vw;

      @media (max-width: 1024px){
         padding: 0;
      }  
  
      .row-item-component {
         margin-top: 15.994px;

         &:first-child {
            margin-top: 0;
         }

         &--title {
            font-weight: normal;
            color: rgba(white, 0.5);
            margin-bottom: 0.417vw;
            font-size: 0.729vw;

            @media (max-width: 767px) {
               margin-bottom: 2.222vw;
               font-size: 3.889vw;
            }
         }
      }
   }

   &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.25vw 0 0 0.8333333333333334vw;
      font-size: 0.8333333333333334vw;

      @media (max-width: 1365px) {
         font-size: 1.172vw;
      }

      @media (max-width: 1024px){
         padding: 16px 0 0 0;
      }
  
      @media (max-width: 767px) {
         font-size: 16px;
      }

      & b {
         font-size: 16px;

         @media (max-width: 1365px){
            font-size: 16px;
         }
     
         @media (max-width: 1024px){
            font-size: 1.563vw;
         }
     
         @media (max-width: 767px){
            font-size: 16px;
         }
      }

      & .row-item-component {
         &.action {
            display: none;
         }

         &:nth-last-child(2) {
            margin-right: 2.608vw;
         }

         &:nth-child(2) {
            margin-right: auto;
            flex: 1;
         }
      }

      &--button {
          background-color: transparent;
          border: 0;
          outline: 0;
          transition: 0.2s transform;
          cursor: pointer;
          &:active {
              transform: scale(1.12);
          }

          & img {
             transition: 0.2s all;
             width: 24px;
             height: 24px;
          }
      }
      
   }
}
