* {
    font-family: Chivo, 'Courier New', Courier, monospace;
}

.register-now-link {
    margin-top: 59px;
    width: 312px;
    max-width: 100%;
    height: 64px;
    border-radius: 32px;
    background-color: #ff7676;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    color: white;
    text-decoration: none;
    border: 0;
    transition: 0.2s all;
    cursor: pointer;
    
    &:focus {
        outline: 0;
    }

    &:active {
        transform: scale(1.03);
    }

    @media (max-width: 767px) {
        min-width: 296px;
        height: 56px;
        border-radius: 32px;
        background-color: #ff7676;
        margin-top: 32px;
        max-width: 100%;
    }

    &:focus {
        outline: 0;
    }
}